export class User {
  id!: number;
  username!: string;
  password!: string;
  firstName!: string;
  lastName!: string;
  token!: string;
  role!: string;
  enableAdvanceTable!: number;
  enableAddress!: number;
  enablePaxDetails!: number;
  enableSelfPaid!: number;
  enableBanner!: number;
  enableLecture!: number;
  enableDataFetch!: number;
}
