import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { User } from '../models/user';

const users: User[] = [
  /*
  {
    id: 1,
    username: 'admin',
    password: 'P@ssw0rd',
    firstName: 'Admin',
    lastName: 'Admin',
    token: 'admin-token',
  },
  {
    id: 2,
    username: 'spandau',
    password: 'P@ssw0rd',
    firstName: 'Spandau',
    lastName: 'Chan',
    token: 'admin-token',
  },
  {
    id: 3,
    username: 'kaka',
    password: 'P@ssw0rd',
    firstName: 'KAKA',
    lastName: 'Lam',
    token: 'admin-token',
  },
  {
    id: 4,
    username: 'sandy',
    password: 'P@ssw0rd',
    firstName: 'Sandy',
    lastName: 'Tsang',
    token: 'admin-token',
  },
  {
    id: 5,
    username: 'maysoo',
    password: 'P@ssw0rd',
    firstName: 'May',
    lastName: 'Soo',
    token: 'admin-token',
  },
  {
    id: 6,
    username: 'joseph',
    password: 'P@ssw0rd',
    firstName: 'Joseph',
    lastName: 'Lam',
    token: 'admin-token',
  },
  {
    id: 7,
    username: 'suen',
    password: 'P@ssw0rd',
    firstName: 'Suen',
    lastName: 'Wong',
    token: 'admin-token',
  },
  {
    id: 8,
    username: 'desmond',
    password: 'P@ssw0rd',
    firstName: 'Desmond',
    lastName: 'Leung',
    token: 'admin-token',
  },
   


  */
];

@Injectable()
export class FakeBackendInterceptor implements HttpInterceptor {
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const { url, method, headers, body } = request;
    // wrap in delayed observable to simulate server api call
    return of(null).pipe(mergeMap(handleRoute));

    function handleRoute() {
      switch (true) {
        case url.endsWith('/authenticate') && method === 'POST':
          return authenticate();
        default:
          // pass through any requests not handled above
          return next.handle(request);
      }
    }

    // route functions

    function authenticate() {
      const { username, password } = body;
      const user = users.find(
        (x) => x.username === username && x.password === password
      );
      if (!user) {
        return error('Username or password is incorrect');
      }
      return ok({
        id: user.id,
        username: user.username,
        firstName: user.firstName,
        lastName: user.lastName,
        token: user.token,
      });
    }

    // helper functions

    function ok(body?: any) {
      return of(new HttpResponse({ status: 200, body }));
    }

    function error(message: string) {
      return throwError({ error: { message } });
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    function unauthorized() {
      return throwError({ status: 401, error: { message: 'Unauthorised' } });
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    function isLoggedIn() {
      return headers.get('Authorization') === 'Bearer fake-jwt-token';
    }
  }
}

export const fakeBackendProvider = {
  // use fake backend in place of Http service for backend-less development
  provide: HTTP_INTERCEPTORS,
  useClass: FakeBackendInterceptor,
  multi: true,
};
