import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [
  {
    path: '',
    title: 'MENUITEMS.MAIN.TEXT',
    icon: '',
    class: 'header',
    groupTitle: true,
    submenu: [],
  },
  /**
  {
    path: '',
    title: 'MENUITEMS.HOME.TEXT',
    icon: 'monitor',
    class: 'menu-toggle',
    groupTitle: false,
    submenu: [
      {
        path: '/dashboard/main',
        title: 'MENUITEMS.HOME.LIST.DASHBOARD1',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: [],
      },
      {
        path: '/dashboard/dashboard2',
        title: 'MENUITEMS.HOME.LIST.DASHBOARD2',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: [],
      },
      {
        path: '/dashboard/dashboard3',
        title: 'MENUITEMS.HOME.LIST.DASHBOARD3',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: [],
      },
    ],
  },
  **/
  {
    path: 'advance-table',
    title: 'MENUITEMS.ADVANCE-TABLE.TEXT',
    icon: 'trello',
    class: '',
    groupTitle: false,
    submenu: [],
  },
  {
    path: 'address-table',
    title: 'MENUITEMS.ADDRESS-TABLE.TEXT',
    icon: 'trello',
    class: '',
    groupTitle: false,
    submenu: [],
  },
  {
    path: 'admin-table',
    title: 'MENUITEMS.ADMIN-TABLE.TEXT',
    icon: 'trello',
    class: '',
    groupTitle: false,
    submenu: [],
  },
  {
    path: 'paxdetails',
    title: 'MENUITEMS.PAXDETAILS.TEXT',
    icon: 'users',
    class: '',
    groupTitle: false,
    submenu: [],
  },
  {
    path: 'selfpaid',
    title: 'MENUITEMS.SELFPAID.TEXT',
    icon: 'dollar-sign',
    class: '',
    groupTitle: false,
    submenu: [],
  },

  {
    path: 'banner',
    title: 'MENUITEMS.BANNER.TEXT',
    icon: 'layout',
    class: '',
    groupTitle: false,
    submenu: [],
  },
      
  {
    path: 'lecture',
    title: 'MENUITEMS.LECTURE.TEXT',
    icon: 'monitor',
    class: '',
    groupTitle: false,
    submenu: [],
  },
  {
    path: 'tripshoot',
    title: 'MENUITEMS.TRIPSHOOT.TEXT',
    icon: 'layout',
    class: '',
    groupTitle: false,
    submenu: [],
  },
  {
    path: 'news',
    title: 'MENUITEMS.NEWS.TEXT',
    icon: 'layout',
    class: '',
    groupTitle: false,
    submenu: [],
  },
  {
    path: 'getdata',
    title: 'MENUITEMS.GETDATA.TEXT',
    icon: 'git-pull-request',
    class: '',
    groupTitle: false,
    submenu: [],
  },
  {
    path: 'admin-log',
    title: 'MENUITEMS.ADMIN-LOG.TEXT',
    icon: 'trello',
    class: '',
    groupTitle: false,
    submenu: [],
  },

 
];
