export class itineraryList {
    id!: number;
    itinCode!: string;
    itinStatus!: string;
    longChiName!: string;
    days!: string;
    city!: string;
    tour_category!: string;
    tour_price!: string;
    tour_depart_date!: string;
    category_code!: string;
    departureDateFrom!: string;
    departureDateTo!: string;
    status!: string;
  }

  export class catList {
   categoryId!: string;
   parentId!: string;
   parentName!: string;
   parentIndex!: string;
   categoryLevel!: string;
   categoryName!: string;
   categoryIndex!: string;
   status!: string; 
  }

  export class imgList {
    tourCode!: string;
    tourImageName!: string;
    tourImageCaption!: string;
    tourImageIndex!: string;
  }

  export class getTour {
    itinCode!: string;
    tourCode!: string;
    token!: string;
  }

  export class getItinerary{
    itinCode!: string;
    token!: string;
  }

  export class lecture{
    lectureName!: string;
    applicantName1!: string;
    applicantName2!: string;
    applicantGender1!: string;
    applicantGender2!: string;
    applicantPhone!: string;
    applyDate!: string;
  }

  export class paxdetails{
    id!: number;
    bookerTitle!: string;
    bookerSurname!: string;
    bookerGivenname!: string;
    bookerPhone!: string;
    bookerEmail!: string;
    bookerSpecialRequests!: string;
    paxTitle!: string;
    paxSurname!: string;
    paxGivenname!: string;
    paxChiname!: string;
    paxDob!: string;
    paxHkid!: string;
    paxPhone!: string;
    paxPassportType!: string;
    paxPassportNumber!: string;
    paxPassportIssuedDate!: string;
    paxPassportExpiryDate!: string;
    bookingNumber!: string;
    uid!: string;
  }
  
  export class LectureCount {
    lectureName!: string;
    applicantCount!: number;
} 
export class adminLog{
  id!: string;
  action!: string;
  userId!: string;
  createdAt!: string;
}